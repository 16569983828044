@import '../../sassStyles/global-imports';

.edit-bucket-properties__text a {
  margin-left: 1rem;
  @include lgMinBreakPoint {
    margin-top: 0.1rem;
    margin-left: initial;
  }
}

.table-padding {
  @include lgMinBreakPoint {
    padding: 1.5rem;
  }
}

.rule-btns {
  flex-direction: column-reverse;

  @include smMinBreakPoint {
    justify-content: space-between;
    flex-direction: row;
  }

}

.rule-btns-row {
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;

  @include smMinBreakPoint {
    width: auto;
    margin-bottom: 0;
  }
}

.btn-create-rule {
  @include smMinBreakPoint {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.btn-create-rule a {
  @include smMinBreakPoint {
    width: fit-content;
    align-self: center;
  }
}